
















































































































import Avatar from '@/modules/community/common/components/avatar.vue'
import { Observer } from 'mobx-vue'
import { Component, Ref, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

import { recruitmentApplyController } from '@/modules/recruitment/dialogs/recruitment-apply-controller'

@Observer
@Component({
  components: {
    Avatar,
    'confirm-discard-post-dialog': () => import('@/modules/common/dialogs/post/confirm-discard-post-dialog.vue'),
  },
})
export default class extends Vue {
  @Ref('form') form: any
  controller = recruitmentApplyController
  submitLoading = false
  walletStore = walletStore

  get heightInput() {
    return this.$vuetify.breakpoint.smOnly ? 44 : 50
  }
  get bgInput() {
    return this.$vuetify.breakpoint.smOnly ? 'primary darken-1' : 'primary darken-2'
  }

  async submitApply() {
    if (!this.form.validate()) return
    this.submitLoading = true
    await this.controller.submitApply()
    this.submitLoading = false
  }
}
